<template>
  <div class="cover-type">
    <div v-for="(config, index) in coverConfig" :key="index"
         class="cover-type-item"
         @click="$emit('change', config.name)">
      <cover-holder :src="config.cover" :height="coverHeight" :selected="activeConfig === config.name">
        <div class="style-tag" :class="{primary: config.new}" v-if="config.upload || config.new">
          <i class="fa fa-image" v-if="!config.new" />
          <strong class="text-mini" v-else>New</strong>
        </div>
      </cover-holder>
    </div>
  </div>
</template>

<script>
import CoverHolder from '../../../components/CoverHolder'
export default {
  name: 'cover-type',
  components: { CoverHolder },
  props: {
    activeConfig: {
      type: String,
      default: 'type-2'
    }
  },
  data () {
    return {
      coverConfig: [
        {
          name: 'type-12',
          cover: 'https://canvas.xinshu.me/generate/?name=type-12&size=320',
          upload: true,
          color: false,
          new: false
        },
        {
          name: 'type-13',
          cover: 'https://canvas.xinshu.me/generate/?name=type-13&size=320',
          upload: true,
          color: false,
          new: false
        },
        {
          name: 'type-14',
          cover: 'https://canvas.xinshu.me/generate/?name=type-14&size=320',
          upload: true,
          color: false,
          new: false
        },
        {
          name: 'type-1',
          cover: 'https://canvas.xinshu.me/generate/?name=cover-psb-1&size=320',
          upload: true,
          color: true
        },
        {
          name: 'type-2',
          cover: 'https://canvas.xinshu.me/generate/?name=cover-psb-2&size=320',
          upload: true,
          color: true
        },
        {
          name: 'type-3',
          cover: 'https://canvas.xinshu.me/generate/?name=cover-psb-3&size=320',
          upload: true,
          color: true
        },
        {
          name: 'type-4',
          cover: 'https://canvas.xinshu.me/generate/?name=cover-psb-4&size=320',
          upload: true,
          color: true
        },
        {
          name: 'type-5',
          cover: 'https://canvas.xinshu.me/generate/?name=cover-psb-5&size=320',
          upload: true,
          color: true
        },
        {
          name: 'type-11',
          cover: 'https://canvas.xinshu.me/generate/?name=type-11&size=320',
          upload: false,
          color: false,
          new: false
        },
        {
          name: 'type-6',
          cover: 'https://canvas.xinshu.me/generate/?name=cover-psb-6&size=320',
          upload: false
        },
        {
          name: 'type-7',
          cover: 'https://canvas.xinshu.me/generate/?name=cover-psb-7&size=320',
          upload: false
        },
        {
          name: 'type-8',
          cover: 'https://canvas.xinshu.me/generate/?name=cover-psb-8&size=320',
          upload: false
        },
        {
          name: 'type-9',
          cover: 'https://canvas.xinshu.me/generate/?name=cover-psb-9&size=320',
          upload: false
        },
        {
          name: 'type-10',
          cover: 'https://canvas.xinshu.me/generate/?name=cover-psb-10&size=320',
          upload: false
        }
      ],
      coverHeight: 141.1
    }
  },
  created () {}
}
</script>

<style type="text/scss" lang="scss">
@import '../../../styles/variables';
.cover-type {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  margin-top: .5rem;
  .row {
    flex: 1;
  }
  &-item {
    width: 25%;
    flex: 0 0 25%;
    position: relative;
    padding: 5px;
    overflow: hidden;
    &.active {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0,.3);
      }
    }
  }
}
</style>
