<template>
  <ul class="color-list row">
    <div class="col-xs-10">
      <li v-for="(color, index) in colors" :key="index"
          :style="{backgroundColor: color, border: index < 4 ? '1px solid #eee' : 'none'}"
          :class="{'check': coverColor === color}"
          @click="$emit('change', color)">
        <i class="ion-checkmark-round text-white"></i>
      </li>
    </div>
  </ul>
</template>

<script>
import config from 'src/app.config'
export default {
  name: 'color-list',
  props: {
    coverColor: String
  },
  data () {
    return {
      colors: config.themeList
    }
  },
  created () {}
}
</script>

<style type="text/scss" lang="scss">
.color-list {
  overflow: hidden;
  li {
    position: relative;
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    float: left;
    height: 40px;
    width: 40px;
    margin: 5px 8px 0 5px;
    border-radius: 50%;
    i {
      display: none;
    }
    &.check {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0,.3);
      }
      i {
        display: inline-block;
        line-height: 40px;
        position: relative;
        z-index: 2;
      }
    }
  }
}
</style>
