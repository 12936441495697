import { render, staticRenderFns } from "./InsertPage.vue?vue&type=template&id=f13bde1e&"
import script from "./InsertPage.vue?vue&type=script&lang=js&"
export * from "./InsertPage.vue?vue&type=script&lang=js&"
import style0 from "./InsertPage.vue?vue&type=style&index=0&id=f13bde1e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports